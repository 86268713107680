import { useGetEducationalProgramsForAppQuery as useQuery } from "@bespeak/apollo";

export function useGetEducationalProgramsForApp() {
    const { data, ...results } = useQuery();

    return {
        educationalPrograms: data?.getEducationalProgramsForApp,
        ...results,
    };
}

export default useGetEducationalProgramsForApp;

export type EducationalProgramsForApp = NonNullable<
    ReturnType<typeof useGetEducationalProgramsForApp>["educationalPrograms"]
>;
